import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import mapHeroImg from "../images/map2.jpg";
import fbIcon from "../images/fb-icon.png";
import appStore from "../images/app-store.png";
import iphoneAppSs from "../images/iphone-app-ss.png";
import styleGuide from "../images/style-guide.png";
import mapDemo from "../images/map-demo.png";
import globe from "../images/globe.png";
import lists from "../images/lists.png";
import beerPour from "../images/beer-pour.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Finding Beer"
        keywords={[`breweries`, `finding.beer`, `map`, `top`]}
      />
      {/* Navigation */}
      <div
        class="bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${mapHeroImg})` }}
      >
        {/* Hero */}
        <div className="pt-24 text-shadow text-white">
          <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <div className="flex flex-col w-full md:w-3/5 justify-center items-start text-center md:text-left max-w-screen-sm">
              <h2 className="my-4 text-5xl font-bold leading-tight">
                For the beer loving-traveler
              </h2>
              <p className="leading-normal text-2xl mb-24">
                Find the best breweries on a color coded map so you always know
                what to drink when you're away from home.
                <br />
                <br />
                <a
                  className=" no-underline text-gray-800 text-bold"
                  href="https://apps.apple.com/us/app/finding-beer/id1082135126"
                >
                  <img
                    className="h-10 fill-current inline rounded-lg"
                    src={appStore}
                    alt="Screenshot of iphone application"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Waves SVG */}
        <div class="relative -mt-12 lg:-mt-24">
          <svg
            viewBox="0 0 1428 174"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(-2.000000, 44.000000)"
                fill="#FFFFFF"
                fill-rule="nonzero"
              >
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  id="Path-4"
                  opacity="0.200000003"
                ></path>
              </g>
              <g
                transform="translate(-4.000000, 76.000000)"
                fill="#FFFFFF"
                fill-rule="nonzero"
              >
                <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
              </g>
            </g>
          </svg>
        </div>
      </div>

      {/* List Links */}
      <section className="bg-white border-b py-8">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
        <h3 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Top Brewery Lists
          </h3>
          
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            <p className="mt-8 text-center px-8 w-96 sm:w-max m-auto">Don't have the iPhone App? That's ok, you can now browse the best breweries on our website!</p>
          </div>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm block m-auto"
              href="/best-breweries/list/united-states"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/us-flags_sm.jpg`}
                alt=""
              />
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow hover:bg-slate-100 md:h-64 lg:h-44 xl:h-36">
                <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4 underline">
                  Top Breweries by U.S. State
                </div>
                <p className="text-gray-800 text-base px-6 mb-5 mt-2">
                  There are some fantastic breweries in the United States but the most
                  delicious beer is arguably served on tap from one near you.
                </p>
              </div>
            </a>
          </div>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm block m-auto"
              href="/best-breweries/list/country"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/world-flags_sm.jpg`}
                alt=""
              />
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow hover:bg-slate-100 md:h-64 lg:h-44 xl:h-36">
                <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4 underline">
                  Top Breweries by Country
                </div>
                <p className="text-gray-800 text-base px-6 mb-5 mt-2">
                  Explore the best breweries from all the major beer producing countries in the world so
                  you know what to drink on your next international trip.
                </p>
              </div>
            </a>
          </div>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm block m-auto"
              href="/best-breweries/list/metros/"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/metros_sm.jpg`}
                alt=""
              />
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow hover:bg-slate-100 md:h-64 lg:h-44 xl:h-36">
                <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4 underline">
                  Best Breweries By US Metro
                </div>
                <p className="text-gray-800 text-base px-6 mb-5 mt-2">
                  We've compiled a brewery list for hundreds of the largest cities in the US. Ensure that you know what the best brewery 
                  is on your next trip.
                </p>
              </div>
            </a>
          </div>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm block m-auto"
              href="/best-new-breweries/united-states"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/United%20States.jpg`}
                alt=""
              />
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow hover:bg-slate-100 md:h-64 lg:h-48 xl:h-36">
                <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4 underline">
                  Top NEW Breweries In the United States
                </div>
                <p className="text-gray-800 text-base px-6 mb-5 mt-2">
                  New Breweries are constantly opening in the United States. Find out what the best ones are that opened in the last couple years.
                </p>
              </div>
            </a>
          </div>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm block m-auto"
              href="/best-new-breweries/international"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/International.jpg`}
                alt=""
              />
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow hover:bg-slate-100 md:h-64 lg:h-48 xl:h-36">
                <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4 underline">
                Top NEW Breweries Internationally
                </div>
                <p className="text-gray-800 text-base px-6 mb-5 mt-2">
                  Learn about the best new breweries internationally!
                </p>
              </div>
            </a>
          </div>

          <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
            <a
              className="rounded-lg shadow-lg bg-white max-w-sm block m-auto"
              href="/best-breweries/list/international-metros/"
            >
              <img
                class="rounded-t-lg"
                src={`https://cdn.finding.beer/static/images/places/sm/Portugal.jpg`}
                alt=""
              />
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow hover:bg-slate-100 md:h-64 lg:h-48 xl:h-36">
                <div className="w-full font-bold text-xl text-gray-800 px-6 mt-4 underline">
                  Best Breweries By International Metro
                </div>
                <p className="text-gray-800 text-base px-6 mb-5 mt-2">
                  Whether you're on your way to Auckland, Edinburgh, or Sao Paulo, finding beer has your guide to the best beer.
                </p>
              </div>
            </a>
          </div>
          
        </div>
        <a
          className="bg-white max-w-sm block m-auto text-center underline"
          href="/best-breweries"
        >View All the Lists Here
        </a>
      </section>

      {/* App Features */}
      <section className="bg-white border-b py-8">
        <div className="container max-w-5xl mx-auto m-8">
          <h3 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            iOS App Features
          </h3>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <h4 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                A color coded quality scale
              </h4>
              <p className="text-gray-600 mb-8">
                Each brewery is assigned a quality ranking, which is then
                visualized using a sliding color scale. Purple map pins are the
                highest quality breweries, followed by red, orange, yellow and
                gray. You can't visit every brewery so you might as well make an
                informed decision so you drink from the best whereever you go!
              </p>
            </div>
            <div className="w-full sm:w-1/3 pt-2">
              <img src={mapDemo} />
            </div>
          </div>

          <div className="flex flex-wrap flex-col-reverse sm:flex-row mt-6">
            <div className="w-full sm:w-1/3 p-8">
              <img src={styleGuide} />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                <h4 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Know what you're getting into
                </h4>
                <p className="text-gray-600 mb-8">
                  You can quickly see what beer styles are consumed most
                  frequently for each brewery in our database. It's also updated
                  regularly so you won't ever be surprised with a full line up
                  of Saisons if you were really in the mood for a Stout.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap mt-6">
            <div className="w-5/6 sm:w-1/2 p-6">
              <h4 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                Oh, the places you'll go!
              </h4>
              <p className="text-gray-600 mb-8">
                Although finding.beer has its most reliable quality rankings in
                North America, our database has over 25k breweries in 182
                countries. Wherever you are in the world, we're committed to
                making sure you're always are drinking the best beer.
              </p>
            </div>
            <div className="w-full sm:w-1/5 p-6">
              <img className="max-h-60" src={globe} />
            </div>
          </div>

          <div className="flex flex-wrap flex-col-reverse sm:flex-row mt-6">
            <div className="w-full sm:w-1/5 p-8">
              <img src={lists} className="max-h-60" />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                <h4 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Top brewery lists
                </h4>
                <p className="text-gray-600 mb-8">
                  Everybody loves a top 10 list, right? Finding Beer is no
                  different and curates top brewery lists for every state in the
                  US and country in the world. You'll automatically become a
                  much more educated beer enthusiast just by using it!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-20 text-black">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-2/5 ml-auto mr-auto px-4">
              <img alt="..." className="max-w-full" src={iphoneAppSs} />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 className="text-3xl font-semibold text-black">
                  Download the iPhone app
                </h3>
                <p className="mt-4 text-lg leading-relaxed">
                  The best way to experience finding.beer is by{" "}
                  <a
                    href="https://apps.apple.com/us/app/finding-beer/id1082135126"
                    className="text-black underline"
                  >
                    downloading the iOS app
                  </a>
                  . We don't have an android app yet but it's something we're
                  thinking about.
                  <br />
                  <br />
                  <a
                    className="toggleColour no-underline hover:no-underline text-2xl lg:text-5xl text-gray-800 text-bold"
                    href="https://apps.apple.com/us/app/finding-beer/id1082135126"
                  >
                    <img
                      className="h-10 fill-current inline rounded-lg"
                      src={appStore}
                      alt="Screenshot of iOS application"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-20 bg-white">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-lg mb-8"
                src={beerPour}
              />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 className="text-3xl font-semibold text-black">
                  Brewery Color Quality Scale
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  Our algorithm factors in a lot of data to determine its
                  quality score. Here's how you should think about each
                  category:
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mr-3">
                          🟣
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          😍 Reserved for a small list of extrodinary breweries
                          that you should seek out.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mr-3">
                          🔴
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          😄 Fantastic breweries that consistently produce world
                          class beer.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mr-3">
                          🟠
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          🙂 Breweries that make darn good beer but not always
                          the ones that you'll remember
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mr-3">
                          🟡
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          🤨 Breweries that do not necessarily make bad beer but
                          ones that are fairly ordinary.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full mr-3">
                          ⚪
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          🙁 Breweries that most people who enjoy craft beer
                          generally avoid.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Waves SVG */}
      <svg
        className="wave-top"
        viewBox="0 0 1439 147"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
            <g className="wave" fill="#f8fafc">
              <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
            </g>
            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
              <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  opacity="0.200000003"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>

      {/* CTA block */}
      <section className="container mx-auto text-center py-6 mb-12 text-white text-shadow">
        <h3 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
          Like what you see?
        </h3>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <h4 className="my-4 text-3xl leading-tight">
          Tell a friend! We rely on people like you to share the word.
        </h4>
      </section>
    </Layout>
  );
}

export default IndexPage;
